html {
  background: #222;
}
body {
  overflow: hidden;
}
.backdrop {
  position: fixed;
  height: 100%;
  width: 100%;
}
.canvas {
  transform-style: preserve-3d;
  perspective: 5000px;
  background-color: #aaa;
  width: 100%;
}
.scene {
  transform-style: preserve-3d;
  text-align: center;
  width: 100%;
}

.navbar {
  display: flex;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0 0 0 0;
}
.nav-node {
  color: #eee;
  height: 100%;
  padding: 1em;
  transition: 0.3s;
}
.nav-node:hover {
  cursor: pointer;
  background-color: #333;
}

.data-plane {
  background-color: #33333333;
  cursor: pointer;
  left: 0;
  position: absolute;
  top: 0;
  transition: 0.25s;
  width: 75%;
}
.data-plane.drillable {
}
.data-plane:hover,
.data-plane.selected {
  opacity: 1;
  background-color: #333333;
}
.data-plane.drillable:hover {
}
.data-plane.drillable:hover > .data-plane-key-name,
.data-plane.drillable.selected > .data-plane-key-name {
  transform: translateX(1em);
}
.data-plane:hover > .data-plane-value,
.data-plane.selected > .data-plane-value {
  opacity: 0.8;
}
.data-plane-copy {
  color: #fff;
  cursor: pointer;
  padding: 0.5em;
  opacity: 0;
  position: absolute;
  right: 3em;
  top: 2em;
  z-index: 10;
}
.data-plane.selected > .data-plane-copy,
.data-plane:hover > .data-plane-copy {
  opacity: 1;
}
.data-plane-copy:hover {
  opacity: 1;
}
.data-plane-key-name {
  /* background-color: #33333333; */
  /* border-radius: 0.25em; */
  color: #ddd;
  font-size: 3em;
  /* padding: 0.1em 0.3em; */
  position: absolute;
  top: -1.5em;
  white-space: nowrap;
  z-index: 10;
}
.drillable > .data-plane-key-name {
  color: #fff;
  transition: 1s;
}
.data-plane:hover > .data-plane-key-name,
.data-plane.selected > .data-plane-key-name {
  /* background-color: #333333; */
  opacity: 1;
}
.data-plane.drillable:hover > .data-plane-key-name {
  text-decoration: underline;
}
.data-plane-value {
  height: 100%;
  font-weight: normal;
  font-size: 1.0em;
  color: #ccc;
  opacity: 0.4;
}
.data-plane-value-scroll-container {
  padding: 1em;
  overflow: auto;
}
.data-plane-value-scroll-container > pre {
  border-radius: 5px;
  margin-top: 0 !important;
}
